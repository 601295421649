import styled from "styled-components";

export const FooterDiv = styled.div`
  background: black;
  color: rgb(221 221 221 / 90%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 18px;
`;
